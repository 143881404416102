import _ from 'lodash'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'
import $ from "jquery";
import 'slick-carousel'

let screens = resolveConfig(tailwindConfig).theme.screens
let responsive = {} // {sm: true, md: true, lg: true, xl: true, 2xl: true}
let sizes = {} // {sm: 640, md: 768, lg: 1024, xl: 1280, 2xl: 1536}
_.keys(screens).forEach(key => {
  sizes[key] = parseInt(_.get(screens, key, '').replace('px', ''))
  responsive[key] = window.innerWidth <= sizes[key]
})
 
// top(%), left(%)
let carousel = {
  layout: 'lg',
  positions: [
    {
      sm: {
        "carousel_3-17_1":  [2, 2],
        "carousel_3-17_2": [20, 40],
        "carousel_3-17_3": [20, 60],
        "carousel_3-17_4": [60, 10],
        "carousel_3-17_5": [70, 35],
        "carousel_3-17_6": [60, 60],
        "carousel_3-17_text": [15, 10] },
      lg: {
        "carousel_3-17_1":  [13, 3],
        "carousel_3-17_2": [50, 17],
        "carousel_3-17_3": [50, 25],
        "carousel_3-17_4": [35, 60],
        "carousel_3-17_5": [50, 70],
        "carousel_3-17_6": [60, 80],
        "carousel_3-17_text": [10, 40] }
    },
    {
      sm: {
        "carousel_18-24_1": [10, 0],
        "carousel_18-24_2": [5, 15],
        "carousel_18-24_3": [20, 45],
        "carousel_18-24_4": [30, 70],
        "carousel_18-24_5-1": [75, 5],
        "carousel_18-24_5-2": [75, 25],
        "carousel_18-24_6": [90, 40],
        "carousel_18-24_7": [70, 60],
        "carousel_18-24_8": [65, 80],
        "carousel_18-24_text": [15, 10] },
      lg: {
        "carousel_18-24_1": [16, 5],
        "carousel_18-24_2": [0, 12],
        "carousel_18-24_3": [42, 17],
        "carousel_18-24_4": [57, 29],
        "carousel_18-24_5-1": [50, 58],
        "carousel_18-24_5-2": [50, 67],
        "carousel_18-24_6": [75, 75],
        "carousel_18-24_7": [45, 82],
        "carousel_18-24_8": [40, 90],
        "carousel_18-24_text": [10, 40] }
    },
    {
      sm: {
        "carousel_25_1": [3, 5],
        "carousel_25_2": [15, 30],
        "carousel_25_3": [3, 60],
        "carousel_25_4": [100, 100],
        "carousel_25_5": [100, 100],
        "carousel_25_text": [15, 10],
      },
      lg: {
        "carousel_25_1": [20, 2],
        "carousel_25_2": [35, 15],
        "carousel_25_3": [10, 25],
        "carousel_25_4": [9, 50],
        "carousel_25_5": [51, 50],
        "carousel_25_text": [10, 40],
      }
    },
    {
      sm: {
        "carousel_40_1": [10, 5],
        "carousel_40_2": [3, 30],
        "carousel_40_3": [25, 55],
        "carousel_40_4": [70, 20],
        "carousel_40_5": [75, 45],
        "carousel_40_6": [70, 70],
        "carousel_40_text": [15, 10],
      },
      lg: {
        "carousel_40_1": [20, 6],
        "carousel_40_2": [10, 20],
        "carousel_40_3": [50, 30],
        "carousel_40_4": [50, 62],
        "carousel_40_5": [60, 72],
        "carousel_40_6": [50, 80],
        "carousel_40_text": [10, 40],
      }
    },
    {
      sm: {
        "carousel_50_1": [5, 5],
        "carousel_50_2": [15, 30],
        "carousel_50_3": [30, 60],
        "carousel_50_4": [50, 5],
        "carousel_50_5": [60, 35],
        "carousel_50_6": [70, 60],
        "carousel_50_text": [15, 10],
      },
      lg: {
        "carousel_50_1": [10, 5],
        "carousel_50_2": [20, 15],
        "carousel_50_3": [45, 25],
        "carousel_50_4": [45, 60],
        "carousel_50_5": [55, 70],
        "carousel_50_6": [60, 80],
        "carousel_50_text": [10, 40],
      }
    }
  ]
}

window.emitter.on('DOMContentLoaded', event => {
  if (responsive.md || responsive.sm) {
    carousel.layout = 'sm'
  } else {
    carousel.layout = 'lg'
  }

  faces.forEach(face => face.bind())
})

window.emitter.on('resize', (event, config) => {
  if ($) {
    _.keys(config.theme.screens).forEach(key => {
      sizes[key] = parseInt(_.get(config.theme.screens, key, '').replace('px', ''))
      responsive[key] = window.innerWidth <= sizes[key]
    })

    if (responsive.md || responsive.sm) {
      carousel.layout = 'sm'
    } else {
      carousel.layout = 'lg'
    }

    reposition()
  }
})

$(document).ready(function() {
  $("#index-carousel .slick").slick({ autoplay: true, autoplaySpeed: _banner_seconds, dots: true, arrows: false, infinite: true, adaptiveHeight: true, slidesToShow: 1 });
  $("#index-carousel .slick-prev").on('click', () => $("#index-carousel .slick").slick('slickPrev'))
  $("#index-carousel .slick-next").on('click', () => $("#index-carousel .slick").slick('slickNext'))

  $("#doctors .slick").slick({ autoplay: true, autoplaySpeed: 1500, dots: true, arrows: false, infinite: true, adaptiveHeight: true, slidesToShow: 1 });
  $("#doctors .slick-prev").on('click', () => $("#doctors .slick").slick('slickPrev'))
  $("#doctors .slick-next").on('click', () => $("#doctors .slick").slick('slickNext'))

  reposition()
  let animates = ['slide-from-top', 'slide-from-bottom', 'slide-from-left', 'slide-from-right', 'slide-from-scale']
  let timings = ['ease-in', 'ease-out', 'ease-in-out', 'linear']
  $('#carousel').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear'
  })
  // 刪除
  $('#carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    let currentChildren = slick.$slides[currentSlide].firstChild.firstChild.children
    for (let element of currentChildren) {
        // console.log(item.id);
        element.style.animation = 'unset'
    }
    let nextChildren = slick.$slides[nextSlide].firstChild.firstChild.children
    for (let element of nextChildren) {
        // console.log(item.id);
        element.style.opacity = 0
    }
  });
  // 增加
  $('#carousel').on('afterChange', function(event, slick, currentSlide) {
    let children = slick.$slides[currentSlide].firstChild.firstChild.children
    for (let element of children) {
        // console.log(item.id);
        element.style.opacity = 1
        element.style.animation = `${animates[Math.floor(Math.random()*animates.length)]} ${Math.floor(Math.random() * (20 - 5 + 1) + 5) * 0.1}s ${timings[Math.floor(Math.random()*timings.length)]}`
    }
  });

  play(0);
  play(1);
  play(2);
  play(3);
})

let faces = [{
  img: document.getElementById('index_face_1'),
  glasses: document.getElementById('index_face_1_glasses').children, // HTMLCollection
  glassesIndex: -1 /* 目前顯示眼鏡 */, index: 0 /* 目前顯示格數 */, total: 4 /* 總格數 */, width: 262, height: 211, interval: 500, bind: () => {
    let clearActiveExcept = (index = -1) => {
      if (index === 0) { let list = document.getElementById('face_1_side_0').classList; if (!list.contains('text-face1')) { list.add('text-face1') } } else { document.getElementById('face_1_side_0').classList.remove('text-face1'); }
      if (index === 1) { let list = document.getElementById('face_1_side_1').classList; if (!list.contains('text-face1')) { list.add('text-face1') } } else { document.getElementById('face_1_side_1').classList.remove('text-face1'); }
      if (index === 2) { let list = document.getElementById('face_1_side_2').classList; if (!list.contains('text-face1')) { list.add('text-face1') } } else { document.getElementById('face_1_side_2').classList.remove('text-face1'); }
      if (index === 3) { let list = document.getElementById('face_1_side_3').classList; if (!list.contains('text-face1')) { list.add('text-face1') } } else { document.getElementById('face_1_side_3').classList.remove('text-face1'); }
    }
    document.getElementById('index_face_1').addEventListener('click', event => { clearActiveExcept(-1);play(0) })
    document.getElementById('index_face_1_glasses').addEventListener('click', event => { clearActiveExcept(-1);play(0) })
    document.getElementById('face_1_side_0').addEventListener('click', event => { clearActiveExcept(0);side(0);glass(0, 0) })
    document.getElementById('face_1_side_1').addEventListener('click', event => { clearActiveExcept(1);side(0);glass(0, 1) })
    document.getElementById('face_1_side_2').addEventListener('click', event => { clearActiveExcept(2);side(0);glass(0, 2) })
    document.getElementById('face_1_side_3').addEventListener('click', event => { clearActiveExcept(3);side(0);glass(0, 3) })

    for(let glass of document.getElementById('index_face_1_glasses').children) {
      glass.addEventListener("animationend", function(event) {
        if (event.animationName.includes('-to-')) {
          this.classList.add('hidden')
        }
      }.bind(glass))
    }
  }
}, {
  img: document.getElementById('index_face_2'),
  glasses: document.getElementById('index_face_2_glasses').children, // HTMLCollection
  glassesIndex: -1 /* 目前顯示眼鏡 */, index: 0 /* 目前顯示格數 */, total: 4 /* 總格數 */, width: 255, height: 277, interval: 500, bind: () => {
    let clearActiveExcept = (index = -1) => {
      if (index === 0) { let list = document.getElementById('face_2_side_0').classList; if (!list.contains('text-face2')) { list.add('text-face2') } } else { document.getElementById('face_2_side_0').classList.remove('text-face2'); }
      if (index === 1) { let list = document.getElementById('face_2_side_1').classList; if (!list.contains('text-face2')) { list.add('text-face2') } } else { document.getElementById('face_2_side_1').classList.remove('text-face2'); }
      if (index === 2) { let list = document.getElementById('face_2_side_2').classList; if (!list.contains('text-face2')) { list.add('text-face2') } } else { document.getElementById('face_2_side_2').classList.remove('text-face2'); }
      if (index === 3) { let list = document.getElementById('face_2_side_3').classList; if (!list.contains('text-face2')) { list.add('text-face2') } } else { document.getElementById('face_2_side_3').classList.remove('text-face2'); }
      if (index === 4) { let list = document.getElementById('face_2_side_4').classList; if (!list.contains('text-face2')) { list.add('text-face2') } } else { document.getElementById('face_2_side_4').classList.remove('text-face2'); }
      if (index === 5) { let list = document.getElementById('face_2_side_5').classList; if (!list.contains('text-face2')) { list.add('text-face2') } } else { document.getElementById('face_2_side_5').classList.remove('text-face2'); }
    }
    document.getElementById('index_face_2').addEventListener('click', event => { clearActiveExcept(-1);play(1) })
    document.getElementById('index_face_2_glasses').addEventListener('click', event => { clearActiveExcept(-1);play(1) })
    document.getElementById('face_2_side_0').addEventListener('click', event => { clearActiveExcept(0);side(1);glass(1, 0) })
    document.getElementById('face_2_side_1').addEventListener('click', event => { clearActiveExcept(1);side(1);glass(1, 1) })
    document.getElementById('face_2_side_2').addEventListener('click', event => { clearActiveExcept(2);side(1);glass(1, 2) })
    document.getElementById('face_2_side_3').addEventListener('click', event => { clearActiveExcept(3);side(1);glass(1, 3) })
    document.getElementById('face_2_side_4').addEventListener('click', event => { clearActiveExcept(4);side(1);glass(1, 4) })
    document.getElementById('face_2_side_5').addEventListener('click', event => { clearActiveExcept(5);side(1);glass(1, 5) })

    for(let glass of document.getElementById('index_face_2_glasses').children) {
      glass.addEventListener("animationend", function(event) {
        if (event.animationName.includes('-to-')) {
          this.classList.add('hidden')
        }
      }.bind(glass))
    }
  }
}, {
  img: document.getElementById('index_face_3'),
  glasses: document.getElementById('index_face_3_glasses').children, // HTMLCollection
  glassesIndex: -1 /* 目前顯示眼鏡 */, index: 0 /* 目前顯示格數 */, total: 4 /* 總格數 */, width: 250, height: 282, interval: 500, bind: () => {
    let clearActiveExcept = (index = -1) => {
      if (index === 0) { let list = document.getElementById('face_3_side_0').classList; if (!list.contains('text-face3')) { list.add('text-face3') } } else { document.getElementById('face_3_side_0').classList.remove('text-face3'); }
      if (index === 1) { let list = document.getElementById('face_3_side_1').classList; if (!list.contains('text-face3')) { list.add('text-face3') } } else { document.getElementById('face_3_side_1').classList.remove('text-face3'); }
      if (index === 2) { let list = document.getElementById('face_3_side_2').classList; if (!list.contains('text-face3')) { list.add('text-face3') } } else { document.getElementById('face_3_side_2').classList.remove('text-face3'); }
      if (index === 3) { let list = document.getElementById('face_3_side_3').classList; if (!list.contains('text-face3')) { list.add('text-face3') } } else { document.getElementById('face_3_side_3').classList.remove('text-face3'); }
      if (index === 4) { let list = document.getElementById('face_3_side_4').classList; if (!list.contains('text-face3')) { list.add('text-face3') } } else { document.getElementById('face_3_side_4').classList.remove('text-face3'); }
    }
    document.getElementById('index_face_3').addEventListener('click', event => { clearActiveExcept(-1);play(2) })
    document.getElementById('index_face_3_glasses').addEventListener('click', event => { clearActiveExcept(-1);play(2) })
    document.getElementById('face_3_side_0').addEventListener('click', event => { clearActiveExcept(0);side(2);glass(2, 0) })
    document.getElementById('face_3_side_1').addEventListener('click', event => { clearActiveExcept(1);side(2);glass(2, 1) })
    document.getElementById('face_3_side_2').addEventListener('click', event => { clearActiveExcept(2);side(2);glass(2, 2) })
    document.getElementById('face_3_side_3').addEventListener('click', event => { clearActiveExcept(3);side(2);glass(2, 3) })
    document.getElementById('face_3_side_4').addEventListener('click', event => { clearActiveExcept(4);side(2);glass(2, 4) })

    for(let glass of document.getElementById('index_face_3_glasses').children) {
      glass.addEventListener("animationend", function(event) {
        if (event.animationName.includes('-to-')) {
          this.classList.add('hidden')
        }
      }.bind(glass))
    }
  }
}, {
  img: document.getElementById('index_face_4'),
  glasses: document.getElementById('index_face_4_glasses').children, // HTMLCollection
  glassesIndex: -1 /* 目前顯示眼鏡 */, index: 0 /* 目前顯示格數 */, total: 4 /* 總格數 */, width: 239, height: 270, interval: 500, bind: () => {
    let clearActiveExcept = (index = -1) => {
      if (index === 0) { let list = document.getElementById('face_4_side_0').classList; if (!list.contains('text-face4')) { list.add('text-face4') } } else { document.getElementById('face_4_side_0').classList.remove('text-face4'); }
      if (index === 1) { let list = document.getElementById('face_4_side_1').classList; if (!list.contains('text-face4')) { list.add('text-face4') } } else { document.getElementById('face_4_side_1').classList.remove('text-face4'); }
      if (index === 2) { let list = document.getElementById('face_4_side_2').classList; if (!list.contains('text-face4')) { list.add('text-face4') } } else { document.getElementById('face_4_side_2').classList.remove('text-face4'); }
      if (index === 3) { let list = document.getElementById('face_4_side_3').classList; if (!list.contains('text-face4')) { list.add('text-face4') } } else { document.getElementById('face_4_side_3').classList.remove('text-face4'); }
      if (index === 4) { let list = document.getElementById('face_4_side_4').classList; if (!list.contains('text-face4')) { list.add('text-face4') } } else { document.getElementById('face_4_side_4').classList.remove('text-face4'); }
    }
    document.getElementById('index_face_4').addEventListener('click', event => { clearActiveExcept(-1);play(3) })
    document.getElementById('index_face_4_glasses').addEventListener('click', event => { clearActiveExcept(-1);play(3) })
    document.getElementById('face_4_side_0').addEventListener('click', event => { clearActiveExcept(0);side(3);glass(3, 0) })
    document.getElementById('face_4_side_1').addEventListener('click', event => { clearActiveExcept(1);side(3);glass(3, 1) })
    document.getElementById('face_4_side_2').addEventListener('click', event => { clearActiveExcept(2);side(3);glass(3, 2) })
    document.getElementById('face_4_side_3').addEventListener('click', event => { clearActiveExcept(3);side(3);glass(3, 3) })
    document.getElementById('face_4_side_4').addEventListener('click', event => { clearActiveExcept(4);side(3);glass(3, 4) })

    for(let glass of document.getElementById('index_face_4_glasses').children) {
      glass.addEventListener("animationend", function(event) {
        if (event.animationName.includes('-to-')) {
          this.classList.add('hidden')
        }
      }.bind(glass))
    }
  }
}]

// face
function play(faceIndex = -1) {
  if (faceIndex < 0) {
    return
  }

  glass(faceIndex, -1)

  faces[faceIndex].index += 1

  // 改為不停
  if (faces[faceIndex].index >= faces[faceIndex].total) {
    if (faces[faceIndex].timer) {
      faces[faceIndex].index = 0
    }
  }

  // 改為不停
  // if (faces[faceIndex].index === faces[faceIndex].total) {
  //   if (faces[faceIndex].timer) {
  //     stop(faceIndex)
  //   }
  // } else {
    faces[faceIndex].img.style.backgroundPosition = `${faces[faceIndex].index * faces[faceIndex].width * -1}px 0px`
    if (!faces[faceIndex].timer)
      faces[faceIndex].timer = setInterval(play.bind(faces[faceIndex], faceIndex), faces[faceIndex].interval);
  // }
}

// face stop
function stop(faceIndex = 0, stopInIndex = 0) {
  if (faces[faceIndex].timer) {
    clearInterval(faces[faceIndex].timer);
    faces[faceIndex].timer = null
  }
  faces[faceIndex].index = stopInIndex
  faces[faceIndex].img.style.backgroundPosition = `${faces[faceIndex].index * faces[faceIndex].width * -1}px 0px`
}

// face side 面對側面
function side(faceIndex = 0) {
  stop(faceIndex, 1)
}

// face glasss
function glass(faceIndex = 0, glassIndex = -1) {
  if (glassIndex === -1) {
    for(let glass of faces[faceIndex].glasses) {
      glass.classList.add('hidden')
      glass.style.animation = 'unset'
    }
    faces[faceIndex].glassesIndex = glassIndex

    return
  }
  if (faces[faceIndex].glassesIndex >= 0) {
    if (faces[faceIndex].glassesIndex > glassIndex) {
      faces[faceIndex].glasses[glassIndex].style.animation = 'slide-from-top 1s linear'
      faces[faceIndex].glasses[glassIndex].classList.remove('hidden')
      faces[faceIndex].glasses[faces[faceIndex].glassesIndex].style.animation = 'slide-to-bottom 1s linear'
    } else if (faces[faceIndex].glassesIndex < glassIndex) {
      faces[faceIndex].glasses[glassIndex].style.animation = 'slide-from-bottom 1s linear'
      faces[faceIndex].glasses[glassIndex].classList.remove('hidden')
      faces[faceIndex].glasses[faces[faceIndex].glassesIndex].style.animation = 'slide-to-top 1s linear'
    }
  } else {
    faces[faceIndex].glasses[glassIndex].style.animation = 'slide-from-bottom 1s linear'
    faces[faceIndex].glasses[glassIndex].classList.remove('hidden')
  }
  faces[faceIndex].glassesIndex = glassIndex
}

// carousel rwd
function reposition() {
  carousel.positions.forEach(position => {
    _.keys(position[carousel.layout]).forEach((id, value) => {
      let elem = document.getElementById(id)
      elem.style.top = `${position[carousel.layout][id][0]}%`
      elem.style.left = `${position[carousel.layout][id][1]}%`
    })
  })
}